export const theme = {
  colors: {
    primary: "#5723e4",
    primarydark: "#232c51",
    primarylight: "#9255ff",
    dark: "rgb(1,2,7)",
    light: "#ffffff",
    secondarydark: "#34515e",
    secondary: "#607d8b",
    secondarylight: "#8eacbb",
    label: "#004766",
    accent: "#1de9b6",
    success: "#01BA6C",
    error: "#ff605e",
    pending: "#ffc45e",
    gray: "#F6F6F6",
    palette: {
      blueDark: "#4B7BEC",
      blue: "#45AAF2",
      purple: "#A65EEA",
      red: "#FC5C65",
      orange: "#FD9644",
      yellow: "#FED330",
      green: "#26DE81",
      teal: "#2BCBBA",
      greyLight: "#D1D8E0",
      greyDark: "#778CA3",
    },
  },
  fonts: {
    asap: ["asap", "sans-serif"],
    opensans: ["Open Sans", "sans-serif"],
  },
  fontSizes: {
    xs: "0.75rem",
    sm: "0.875rem",
    base: "1rem",
    lg: "1.125rem",
    xl: "1.25rem",
    xl2: "1.5rem",
    xl3: "1.875rem",
    xl4: "2.25rem",
    xl5: "3rem",
    xl6: "3.75rem",
    xl7: "4.5rem",
    xl8: "6rem",
    xl9: "8rem",
  },
  boxShadows: {
    medium:
      "0 2.8px 2.2px rgba(0, 0, 0, 0.034), 0 6.7px 5.3px rgba(0, 0, 0, 0.048),0 12.5px 10px rgba(0, 0, 0, 0.06)",
    large:
      "0 2.8px 2.2px rgba(0, 0, 0, 0.034), 0 6.7px 5.3px rgba(0, 0, 0, 0.048),0 12.5px 10px rgba(0, 0, 0, 0.06),0 22.3px 17.9px rgba(0, 0, 0, 0.072),0 41.8px 33.4px rgba(0, 0, 0, 0.086),0 100px 80px rgba(0, 0, 0, 0.12)",
  },
  screens: {
    sm: "640px",
    // => @media (min-width: 640px) { ... }
    md: "768px",
    // => @media (min-width: 768px) { ... }
    lg: "1024px",
    // => @media (min-width: 1024px) { ... }
    xl: "1280px",
    // => @media (min-width: 1280px) { ... }
    "2xl": "1536px",
    // => @media (min-width: 1536px) { ... }
  },
  margins: {
    m0: "0px",
    m1: "0.25rem",
    m2: "0.5rem",
    m3: "0.75rem",
    m4: "1rem",
    m5: "1.25rem",
    m6: "1.5rem",
    m7: "1.75rem",
    m8: "2rem",
    m9: "2.25rem",
    m10: "2.5rem",
    m11: "2.75rem",
    m12: "3rem",
    m14: "3.5rem",
    m16: "4rem",
    m20: "5rem",
    m24: "6rem",
    m28: "7rem",
    m32: "8rem",
    m36: "9rem",
    m40: "10rem",
    m44: "11rem",
    m48: "12rem",
    m52: "13rem",
    m56: "14rem",
    m60: "15rem",
    m64: "16rem",
    m72: "18rem",
    m80: "20rem",
    m96: "24rem",
  },
  paddings: {
    p0: "0px",
    p1: "0.25rem",
    p2: "0.5rem",
    p3: "0.75rem",
    p4: "1rem",
    p5: "1.25rem",
    p6: "1.5rem",
    p7: "1.75rem",
    p8: "2rem",
    p9: "2.25rem",
    p10: "2.5rem",
    p11: "2.75rem",
    p12: "3rem",
    p14: "3.5rem",
    p16: "4rem",
    p20: "5rem",
    p24: "6rem",
    p28: "7rem",
    p32: "8rem",
    p36: "9rem",
    p40: "10rem",
    p44: "11rem",
    p48: "12rem",
    p52: "13rem",
    p56: "14rem",
    p60: "15rem",
    p64: "16rem",
    p72: "18rem",
    p80: "20rem",
    p96: "24rem",
  },
}
