/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.com/docs/browser-apis/
 */

// Normalize css
require("./src/styles/global.css")

require("firebase/auth")
require("firebase/database")

const React = require("react")
const { ThemeProvider } = require("styled-components")

const { theme } = require("./src/styles/theme")

exports.wrapRootElement = ({ element }) => {
  return <ThemeProvider theme={theme}>{element}</ThemeProvider>
}
